export default function () {
    const modals = document.querySelectorAll('.js-modal');
    modals.forEach((element) => {
        const triggerElement = element.querySelector('.js-modal-trigger');
        const modalView = element.querySelector('.js-modal-view');
        const modalContent = element.querySelector('.js-modal-content');

        triggerElement.addEventListener('click', () => {
            document.body.style.overflow = 'hidden';
            modalView.classList.add('active');
        });

        modalView.addEventListener('click', (event) => {
            if (!modalContent.contains(event.target)) {
                document.body.style.overflow = '';
                modalView.classList.remove('active');
            }
        });

        const closeElement = element.querySelector('.js-modal-close');
        closeElement.addEventListener('click', () => {
            document.body.style.overflow = '';
            modalView.classList.remove('active');
        });
    });
}
