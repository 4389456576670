import mobileHeader from './mobileHeader';

class SmoothAnchorScroll {
    constructor(paddingTop = 250) {
        this.location = window.location;
        this.paddingTop = paddingTop;
        this.hash = null;

        this.registerEventListeners();

        if (this.location.hash) {
            this.hash = this.location.hash;
        }

        const manualAnchor = document.querySelector('#js-manual-scroll-anchor');
        if (manualAnchor) {
            this.hash = '#js-manual-scroll-anchor';
        }

        if (!this.hash) {
            return;
        }

        this.scrollToAnchor();
    }

    registerEventListeners() {
        const anchors = document.querySelectorAll('.js-click-scroll-anchor');
        anchors.forEach((item) => {
            item.addEventListener('click', this.handleClick.bind(this));
        });
    }

    handleClick(event) {
        if (mobileHeader.mobileHeader.isMobileShown) {
            mobileHeader.mobileHeader.hideMobileHeader();
        }

        this.hash = SmoothAnchorScroll.getHashFromUrlString(event.currentTarget.href);
        const targetLocation = event.target.href.replace(`/${this.hash}`, '');
        const windowLocation = window.location.href.replace(`/${window.location.hash}`, '');

        if (targetLocation !== windowLocation) {
            return;
        }

        event.preventDefault();
        this.addHashToUrl();
        this.scrollToAnchor(event.target.href);
    }

    addHashToUrl() {
        window.history.pushState(null, null, this.hash);
    }

    scrollToAnchor(href) {
        const anchor = document.querySelector(this.hash);
        if (!anchor) {
            return;
        }

        const {top} = anchor.getBoundingClientRect();

        if ((top + window.scrollY) > (document.body.clientHeight - window.innerHeight)) {
            window.location = href;
            return;
        }

        const scrollTop = top + window.scrollY - this.paddingTop;

        window.scrollTo({
            top: scrollTop,
            behavior: 'smooth',
        });
    }

    static getHashFromUrlString(url) {
        return url.substring(url.indexOf('#'));
    }
}

const smoothAnchorScroll = {
    init() {
        // skip for IE or Edge
        if (document.documentMode || /Edge/.test(navigator.userAgent)) return;

        this.smoothAnchor = new SmoothAnchorScroll();
    },
};

export default smoothAnchorScroll;
